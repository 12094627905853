<template lang="pug">
    .product-wrap.mt-3
        b-row.main-block.px-4
            b-col.p-0.title-block.pb-2.pt-2(cols="12")
                a(href="javascript:void(0)")
                    h4.light-font {{title}}
            b-col.middle-block.p-0(cols="12" md="7") 
                .date-block.mt-grey(v-if="Object.keys(dateInfo).length !== 0")
                    div {{dateInfo.date}}
                    div {{dateInfo.group}}
                .compability-block.mt-blue
                    strong.pb-2.mb-3 Compatibility
                    .brand-model-wrap
                        b-row.m-0.parent-row(v-if="brands.length")
                            b-col.parent-col-left(cols="12" md="2") Brand
                            b-col.parent-col-right(cols="12" md="10") 
                                b-row.child-row
                                    b-col.child-col(cols="12" md="auto" v-for="(brand, index) in brands" :brand="brand" :key="index") 
                                        b-icon-dot
                                        | {{brand}} 
                        b-row.m-0.parent-row(v-if="models.length")
                            b-col.parent-col-left(cols="12" md="2" :class="{'border-top-0': brands.length}") Models
                            b-col.parent-col-right(cols="12" md="10" :class="{'border-top-0': brands.length}") 
                                b-row.child-row
                                    b-col.child-col(cols="12" md="auto" v-for="(model, index) in models" :model="model" :key="index") 
                                        b-icon-dot
                                        | {{model}}

                    //- table.brand-table.w-100.gray.table-responsive-sm.mb-3
                    //-     thead
                    //-         tr(v-if="brands.length")
                    //-             th.comp-th.mt-grey Brand
                    //-             td.comp-td
                    //-                 ul.model-ul
                    //-                     li.model-li(v-for="(brand, index) in brands" :brand="brand" :key="index") {{brand}} 
                    //-         tr(v-if="models.length")
                    //-             th.comp-th.mt-grey Models
                    //-             td.comp-td
                    //-                 ul.model-ul
                    //-                     li.model-li(v-for="(model, index) in models" :model="model" :key="index") {{model}}

                .features-block
                    .features-title
                        strong.mt-blue Features
                    .features-list-wrap
                        ul.features-list
                            li(v-for="(feature, index) in features" :feature="feature" :key="index") 
                                b-icon-dot
                                | {{feature}} 
    
            b-col(cols="12" md="5").middle-block.photo-block(background="white" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;")
                .carousel-wrap.d-none.d-md-block
                    b-carousel#carousel-1(controls indicators)
                        b-carousel-slide(v-for="(imageUrl, i) in imageUrls" :imageUrl="imageUrl" :img-src="imageUrl" :key="i")
                .download-block(v-if="downloadUrls.length")
                    h5.mt-blue Download
                    ul.downloads-list
                        li(v-for="(downloadUrl, index) in downloadUrls" :downloadUrl="downloadUrl" :key="index")
                            a(:href="downloadUrl.url" download) {{downloadUrl.message}}
                   
        .popular-block(v-if="popularProducts.length")
            h5.popular-title.mt-blue Popular products
            table.popular-table.d-none.d-md-block
                thead
                    tr.popular-table-head
                        th.popular-table-head-cell.mt-grey(width="150" scope="col") Part Number
                        th.popular-table-head-cell.mt-grey(scope="col") Description
                        th.popular-table-head-cell.mt-grey(scope="col" width="200") Brands
                        th.popular-table-head-cell.mt-grey(scope="col" width="200") OEM References
                        th.popular-table-head-cell.mt-grey(scope="col" width="150") Package
                tbody
                    tr(v-for="(popular, i) in popularProducts" :key="i")
                        td.popular-table-cell {{ popular.number }}
                        td.popular-table-cell {{ popular.description }}
                        td.popular-table-cell {{ popular.brands }}
                        td.popular-table-cell {{ popular.references }}
                        td.popular-table-cell {{ popular.package }}
            .popular-item-wrap.d-md-none
                .popular-item-group.border(v-for="(popular, i) in popularProducts" :key="i")
                    .popular-item
                        .item-label Part Number:
                        .item-value {{ popular.number }}
                    .popular-item
                        .item-label Description:
                        .item-value {{ popular.description }}
                    .popular-item
                        .item-label Brands:
                        .item-value {{ popular.brands }}
                    .popular-item
                        .item-label OEM References:
                        .item-value {{ popular.references }}
                    .popular-item
                        .item-label Package:
                        .item-value {{ popular.package }}

</template>
<script>
import axios from 'axios';
import { mapState, mapActions } from 'pinia';
import { useBaseStore } from '@/stores/base-st';
import FooterInquiryModal from '@/components/FooterInquiryModal.vue';
const imgLandingBg = require('@/assets/images/landing/landing-footer-bg.svg');
export default {
    name: 'AboutProductItem',
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        dateInfo: {
            type: Object,
            required: true,
            default:  () =>({}),
        },
        brands: {
            type: Array,
            required: true,
            default:  () =>([]),
        },
        models: {
            type: Array,
            required: true,
            default: () =>([]),
        },
        features: {
            type: Array,
            required: true,
            default: () =>([]),
        },
        imageUrls: {
            type: Array,
            required: true,
            default: () =>([]),
        },
        downloadUrls: {
            type: Array,
            required: true,
            default: () =>([]),
        },
        popularProducts: {
            type: Array,
            required: true,
            default: () =>([]),
        }
    },
    data(){
        return {
        };
    },

    computed: {
        ...mapState(useBaseStore, ['message']),
    },
 
    created(){

    },

    mounted(){
    },
    
    watch:{

    },

    methods:{
    },
  
}
</script>

<style scoped lang="scss">
    .brand-model-wrap{
        margin: 0.5rem 0;
        .parent-row{
            .parent-col-left{
                font-family: sans-serif;
                font-size: 1.2rem;
                padding: .5rem;
                border: 1px solid darkgrey;
                border-right: 0;
                background: silver;
                color: #59595B;
                font-weight: 600;
            }
            .parent-col-right{
                padding: .5rem;
                border: 1px solid #c0c0c0;
                color: black;
            }
        }
    }
    .popular-item-wrap{
        margin-bottom: 1rem;
        .popular-item-group{
            .popular-item{
                display: flex;
                min-height: 30px;
                padding: 5px;
                .item-label{
                    width: 40%;
                    font-weight: 500;
                    font-size: 14px;
                }
                .item-value{
                    width: 60%;
                    font-size: 14px;
                }
            }
        }
    }
    a { color: inherit; } 
    .mt-blue {
        color: #68C3C5;
    }
    .mt-grey {
        color: #59595B !important;
    }
    .light-font {
        font-weight: 400;
    }
    .compability-block {
        color: #68C3C5;
    }
    .features-title {
        color: #68C3C5;
    }
    .date-block {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .comp-th {
        width: 100px;
        padding: 10px;
        min-width: 100px;
        font-size: 21px;
        font-family: sans-serif;
        background-color: silver;
        text-align: left;
        border: 1px solid darkgrey;
    }
    .comp-td {
        word-break: break-word;
        border: 1px solid #C0C0C0
    }
    .model-ul {
        margin: auto;
    }
    .model-li {
        color: black;
        float: left;
        width: 29%;
        margin-left: 4%;
        margin-top: 7px;
        margin-bottom: 7px;
    }
    .features-block {
        margin: 20px 0;
    }
    .features-title {
        margin-bottom: 15px;
    }
    .features-list {
        padding: 0;
        list-style-type: none;
    }
    .carousel-wrap {
        border: 1px solid silver;
        margin-bottom: 20px;
    }
    .popular-table-head {
        background-color: silver;
    }
    .popular-table-head-cell {
        border: 1px solid white;
        padding: 5px;
    }
    .popular-table-cell {
        border: 1px solid #F2F2F2;
    }
    .downloads-list {
        padding: 0;
        list-style-type: square;
    }
</style>