<template lang="pug">
.buying-option(
  :class="{ selected: buyingOption.id == product.id }",
  :style="selectedBorder"
)
  .tint( v-if="buyingOption.id == product.id" :style="primaryBgColor")
  b-link(:to="'/product/' + buyingOption.id")
    p.name(:style="primaryColor") {{ title || getBuyingOptionTitle(buyingOption) }}
    p.price(v-if="!shouldShowListPrice", @click="showListPrice = true" :style="primaryColor")
      span {{ $t("global.label.request_quote") }}
    p.price(v-else :style="primaryColor") {{  translatePrice(buyingOption,"list_price") ? (getCurrency + translatePrice(buyingOption,"list_price").toFixed(2)) : (" " + $t('global.label.request_quote'))  }}
      span.unit(v-if="translatePrice(buyingOption, 'list_price')") /{{ getBuyingUnit(buyingOption.packaging_factor) }}
    p.warranty {{ $t("search.label.warranty") }}: {{ buyingOption.warranty_duration_months }} {{ $t("search.label.months") }}
</template>
<script>
import { mapState } from 'pinia';
import { useDbStore } from '@/stores/db-st';
import utils from '@/mixins/utils';

export default {
  mixins: [utils],
  props: {
    buyingOption: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    product: {
      type: Object,
      required: true,
    },
    primaryBgColor: {
      type: Object,
      default: null,
    },
    customer: {
      type: Object,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    isPart: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showListPrice: false,
    };
  },
  computed: {
    ...mapState(useDbStore, ['isMedtenCustomer']),
    shouldShowListPrice() {
      return this.getShouldShowListPrice(
        this.isPart,
        this.isLoggedIn,
        this.showListPrice
      );
    },
    primaryColor() {
      return this.buyingOption.id == this.product.id
        ? `color:${this.primaryBgColor['border-color']}`
        : '';
    },
    selectedBorder() {
      return {
        'border-color':
          this.buyingOption.id == this.product.id
            ? this.primaryBgColor['border-color']
            : 'gray !important',
      };
    },
  },
  methods: {
    getBuyingOptionTitle() {
      if (this.buyingOption.product_type_id < 3) {
        return (
          this.$t('search.label.new') +
          this.translateText(this.buyingOption, 'product_type')
        );
      } else {
        return this.translateText(this.buyingOption, 'product_type');
      }
    },
    getBuyingUnit() {
      if (
        !this.buyingOption.packaging_factor ||
        this.buyingOption.packaging_factor == 1
      ) {
        return 'ea';
      } else if (
        this.buyingOption.packaging_factor.toLowerCase() == 'set' ||
        this.buyingOption.packaging_factor.toLowerCase() == 'kit'
      ) {
        return this.buyingOption.packaging_factor;
      } else {
        return 'box of ' + this.buyingOption.packaging_factor;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.buying-option {
  border-radius: 5px;
  margin: 15px 0;
  background: transparent;
  position: relative;
  border-width: 1px;
  border-style: solid;

  .tint {
    position: absolute;
    opacity: 0.2;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  a {
    padding: 10px 15px;
    text-decoration: none;
    color: gray;
    display: block;
  }

  p {
    margin: 0;
    line-height: 1.7;

    &.name {
      font-size: 14px;
      font-weight: 700;
    }

    &.price {
      font-size: 16px;
      font-weight: 700;
      color: gray;

      .unit {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &.warranty {
      font-size: 12px;
      font-weight: 400;
    }
  }

  &.selected {
    border-width: 3px;
    box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
  }
}
</style>
