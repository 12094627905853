<template>
  <div v-if="!isSubmit">
    <hr />
    <p
      class="main__heading d-flex justify-content-center mt-4 font-weight-light font-weight-bolder"
    >
      {{ $t("website_feedback.your_feedback_appreciated") }}
    </p>
    <p class="d-flex justify-content-center font-weight-light">
      {{ $t("website_feedback.how_satisfied_were_you") }}
    </p>
    <div class="d-flex justify-content-center">
      <b-form-rating
        class="lg"
        inline
        value="4"
        no-border
        v-model="rating_data.rating"
        color="#2EC1D9"
      ></b-form-rating>
    </div>

    <p class="d-flex justify-content-center mt-3 font-weight-light">
      {{ $t("website_feedback.kindly_choose_category") }}
    </p>
    <div class="d-flex justify-content-around">
      <b-button
        :class="{ selected: selectedCategory === 'suggestion' }"
        class="btn"
        variant="outline-primary"
        @click="handleCategorySelection('suggestion')"
      >
      {{ $t("website_feedback.suggestion") }}
      </b-button>
      <b-button
        :class="{ selected: selectedCategory === 'something_off' }"
        class="btn"
        variant="outline-primary"
        @click="handleCategorySelection('something_off')"
      >
       {{ $t("website_feedback.something_seems_bit_off") }}
      </b-button>
      <b-button
        :class="{ selected: selectedCategory === 'compliment' }"
        class="btn"
        variant="outline-primary"
        @click="handleCategorySelection('compliment')"
      >
      {{ $t("website_feedback.compliment") }}   
      </b-button>
    </div>
    <hr />
    <p class="d-flex justify-content-center mt-4 font-weight-light">
      {{ $t("website_feedback.kindly_share_your_thoughts") }} 
    </p>
    <div class="mb-3">
      <b-form-textarea
        v-model="rating_data.comments"
        debounce="500"
        rows="3"
        max-rows="5"
        :placeholder="$t('website_feedback.comment_here')"
      ></b-form-textarea>
    </div>
    <div class="d-flex justify-content-center">
      <b-button
        class="text-light pl-md-4 pr-md-4 mt-4"
        variant="primary"
        @click="submitFeedback"
        >{{ $t("website_feedback.submit_feedback") }} </b-button
      >
      <b-button
        class="text-light pl-md-4 pr-md-4 mt-4 ml-3"
        variant="secondary"
        @click="continueBrowsing"
        >{{ $t("website_feedback.continue_browsing") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "pinia";
import { useDbStore } from "@/stores/db-st";
import { useAuthStore } from "@/stores/auth-st";
import TranslateValue from '@/components/TranslateValue.vue';
export default {
  props: {
    name: String,
    phone: String,
    email: String,
  },
  components:{
  TranslateValue
  },
  data() {
    return {
      selectedCategory: null,
      value: "",
      isSubmit: false,
      rating_data: {
        user_id: "",
        customer_id: "",
        rating: "",
        feedback_category: "",
        comments: "",
      },
    };
  },
  computed: {
    ...mapState(useDbStore, ["customer"]),
    ...mapState(useAuthStore, ["user_principal"]),
  },
  methods: {
    handleCategorySelection(category) {
      this.selectedCategory = category;
      this.rating_data.feedback_category = category;
    },
    continueBrowsing() {
      this.$emit("finish");
    },

    async submitFeedback() {
      try {
        this.rating_data.feedback_category = this.selectedCategory;

        let result = await axios.post(
          process.env.VUE_APP_API_BASE_URL + "/feedback",
          {
            ...this.rating_data,
            phone_number: this.phone,
            name: this.name,
            email: this.email,
            customer_id: this.customer.id,
            user_id: this.user_principal ? this.user_principal.id : null,
            opcCustomerMarketingRegionId:this.customer.marketing_region_id
          }
        );
        this.rating_data = {
          user_id: "",
          customer_id: "",
          rating: "",
          feedback_category: "",
          comments: "",
        };
        this.$emit("finish", true);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.main__heading {
  font-size: 24px;
  line-height: 18px;
}
.selected {
  background-color: #38bcd9;
  color: #fff;
}
.btn:hover {
  color: white;
}
.d-flex .btn {
  margin: 0 5px;
}
.btn-selected {
  background-color: #38bcd9 !important; /* Set the background color for selected button */
  color: #fff; /* Set the text color for selected button */
}
</style>
