<template lang="pug">
b-card.border-white.global-card-block(no-body v-if="item" @click="goToDetails()" :class="{'is-add-url': item.ad_url}")
  b-card-img.rounded(:src="getImgUrl()" alt="Image" :class="`gcard-img-`+item.id" :style="getStyle('border')")
  b-card-body.pl-0.pr-0.text-left(:style="getStyle('bg')")
    b-card-text.h6 
     TranslateValue(:sourceObj="item")
    .sku-price-group.d-flex(v-if="page == 'cav'") 
      .sku {{ $t('search.label.sku') }} : {{item.sku}}
    .cav-info.d-flex(v-if="page == 'cav'") 
      .price-info.w-50(v-if="customer.display_list_price" :style="primaryColor")
        | {{ translatePrice(item, 'list_price') ? translatePrice(item, 'list_price').toFixed(2) : 'Request Quote' }}
        span(v-if="translatePrice(item, 'list_price')") {{ getCurrency }}
      .stock-info(v-if="displayStockInfoForCustomer") {{ product_stock_info }}
      //- toLocaleString('zh-CN', { style:'currency', currency: 'CNY' })
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useBaseStore } from '@/stores/base-st';
import { useProductSearchStore } from '@/stores/product-search-st';
import { useDbStore } from '@/stores/db-st';
import utils from "@/mixins/utils";
import productaddons from "@/mixins/product-addons";
import TranslateValue from '@/components/TranslateValue.vue';
export default {
  name: 'App',
  components: {
    TranslateValue
  },
  mixins: [utils, productaddons],
  props:{
    item:{
      required: true,
      type: Object,
      default: null
    },
    page:{
      required: false,
      type: String,
    }
  },
  data(){
    return {
    };
  },

  computed: {
    ...mapState(useDbStore, ['categories', 'customer', 'isMedtenCustomer','primaryColor', 'secondaryColor', 'primaryBgColor', 'secondaryBgColor', 'primaryBgColorWithoutBorder', 'secondaryBgColorWithoutBorder', 'primaryBorder']),
    ...mapState(useBaseStore, ['message']),
    ...mapState(useProductSearchStore, ['product_search_results','product_search_term','product_search_total','search_criteria_provided']),
    product_stock_info(){
      return this.getProductStock(this.item) > 0? this.$t('global.label.in_stock'): ''; // from mixin
    }
  },
 
  created(){

  },

  mounted(){
    setTimeout(()=>{
      //- const elmnt = document.getElementById(`item`+this.item.id);
      //- if(elmnt) elmnt.style.height = elmnt.offsetWidth + "px";
      const element = document.getElementsByClassName("gcard-img-"+this.item.id);
      element.forEach(function(item){
        if(item) item.style.height = (item.offsetWidth-5) + "px";
      })
    }, 200);
    //- console.log(this.getFullUrl(this.item.image_url));
  },
  
  watch:{

  },

  methods:{
    ...mapActions(useProductSearchStore, ['searchProducts',]),
    getStyle(type){
      switch(type){
        case 'bg':
          if(!this.isMedtenCustomer && this.item.ad_url){
            return this.primaryBgColor;
          }
          return {};
        default: 
          if(this.isMedtenCustomer && this.item.ad_url){
            return this.primaryBorder;
          }
          return {};
      }
    },
    getImgUrl(){
      if(this.item && this.item.image_url) return this.getFullUrl(this.item.image_url);
      return require(`@/assets/images/Default_Missing_Image.png`);
    },
    goToDetails(){
      //- console.log('******', this.page, this.item);
      if(this.page == 'fp' || this.page == 'pi' || this.page == 'na' || this.page == 'cav'){
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(this.item.ad_url){
          window.location.replace(this.item.ad_url);
          //- window.open(this.item.ad_url, '_self');
        }else if(this.$route.params && this.$route.params['product_id'] != this.item.id){
          this.$router.push({ name: 'Product', params: {product_id:  this.item.id} });
        }
      }else if(this.page == 'fc'){
        this.executeProductSearch(this.item.id);
      }
      //- else if(this.page == 'pi' || this.page == 'na'){
      //-   this.$router.push({ name: 'Product', params: {product_id:  this.item.id} });
      //- }
    },

    async executeProductSearch(id){
      this.category_id = id;
      this.search_term = '';
      let parms = {};
      if(this.category_id){
        parms.category_ids = [ this.category_id, ...this.categories.filter(c=>c.parent_id==this.category_id).map(c=>c.id) ];
      }
      //- useProductSearchStore().$reset(); // when executed from here, always starts a totally fresh search.
      await this.searchProducts(parms);
      //Trigger product selection when only one result.
      if(this.product_search_total === 1 && this.product_search_results[0].id){
        //- if(this.$route.name !== 'Product') this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        if(this.$route.name !== 'Product'){ 
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }else if(this.$route.params && this.$route.params['product_id'] && (this.$route.params['product_id'] != this.product_search_results[0].id)){
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }
      } else if (this.product_search_total > 1) {
        this.clearSearch()
        if(this.$route.name !== 'Search'){
          this.$router.push({ name: 'Search', params: {search_term:  this.search_term} });
        } else {
          if(this.product_search_total===0){
            useBaseStore().$patch({ message: this.$t(`global.verbiage.product_not_found`) });
          }
        }
      }
    },
  },
  
}
</script>
<style lang="scss" scoped>
.global-card-block{
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  &.is-add-url{
    img{
      border: 2px solid #fe9301 !important;
      border-radius: 0  !important;
    }
    .card-body{
      padding: .5rem !important;
      margin-bottom: .3rem !important;
      background: #fe9301;
      color: white;
      p{
        color: white;
      }
    }
  }
  img{
    box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
    border-top-left-radius: 20px !important;
    max-height: 280px;
    min-height: 200px;
  }
  .card-body{
    padding: .8rem;
    padding-left: 0;
    padding-right: 0;
    p{
      font-size: .9rem;
      font-weight: 600;
    }
    .sku-price-group{
      .sku{
        font-size: 0.8rem;
        font-weight: 600;
      }
      .price-info{
        font-size: .8rem;
        font-weight: 600;
        color: #38BCD9;
      }
      @media (max-width: 480px){
        .sku{
          font-size: 0.7rem;
        }
        .price-info{
          font-size: 0.7rem;
        }
      }
    }
    .cav-info{
      .stock-info{
        font-weight: 600;
        font-size: .8rem;
        color: #14A757;
      }
      .price-info{
        font-size: .8rem;
        font-weight: 600;
        color: #38BCD9;
      }
      @media (max-width: 480px){
        .price-info{
          font-size: 0.7rem;
        }
      }
    }
  }
}
@media (max-width: 767px){
    .global-card-block{
        padding-left: .5rem;
        padding-right: .5rem;
        img{
          border-top-left-radius: 15px !important;
          max-height: 380px;
          min-height: 160px;
        }
    }
    .card-body{
        padding: 5px 0px;
        p{
            font-size: .7rem !important;
        }
    }
}
@media (max-width: 767px) and (max-width: 767px){

}
</style>