<template lang="pug">
b-container.p-0(fluid v-if="node.visible")
  div(v-if="node.nodes.length > 0" v-show="show")
    b-icon-caret-down-fill.text-primary.mr-1(v-if="select_all")
    b-icon-caret-down.text-primary.mr-1(v-if="!select_all")
    b-link(href="#" @click="toggleSelectAll") {{node.text}}

  b-form-checkbox( v-else :value="node.id" v-model="local_value" :unchecked-value="node.id*(-1)" @change="wasChanged" v-show="show") {{node.text}}

  b-container( :class="show ? '' :  'p-0'")
    FilterTreeNode( v-for="child, idx in node.nodes" :id="child.id" :key="idx" :node="child" :selection="selection" :selected="selection.includes(node.id)"
      @deselect="observeDeselect"
      @select="observeSelect")
</template>

<script>
import FilterTreeNode from './FilterTreeNode';
export default {
  name: 'FilterTreeNode',
  components: {
    FilterTreeNode
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    selection: {
      // all node ids in the tree that are currently selected (from FilterTree)
      type: Array,
      required: false,
      default: ()=>[],
    },
    show: {
      // whether to show this node (default) or just show the children.
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data(){
    return {
      local_value: null,
      is_open: true,
      is_selectable: false,
      select_all: false,
    };
  },
  computed: {
    /** Descendent ids for all descendents, including self. */
    descendent_ids(){
      let ids = [];
      let fct = (node) => {
        ids.push(node.id);
        node.nodes.forEach( n =>  fct(n) );
        return ids;
      };
      return fct(this.node);
    },
  },

  created(){
    // if(!this.node.nodes || this.node.nodes.length===0){ 
    //   this.is_open = false;
    // } else {
    //   this.is_open = this.node.is_open;
    // }

    this.updateSelectionState()
  },

  watch:{
    selected(now, then){
      if(now){ 
        this.local_value = this.node.id;
      } else {
        this.local_value = -1*this.node.id;
      }
    },
  },
 
  methods:{
    observeSelect( payload ){
      this.$emit('select', payload); // bubbles up
    },
    observeDeselect( payload ){
      this.$emit('deselect', payload); // bubbles up
    },
    toggleSelectAll(){
      if(this.select_all){
        console.debug('de-selecting all under %o', this.node.id);
        this.$emit('deselect', this.descendent_ids);
      } else {
        console.debug('selecting all under %o', this.node.id);
        this.$emit('select', this.descendent_ids);
      }
      this.select_all = !this.select_all;
    },
    updateSelectionState(){
      let exists = this.selection.findIndex( id => id == this.node.id );
      if(exists >= 0){ 
        this.local_value = this.node.id; 
      } else {
        this.local_value = -1 * this.node.id;
      }
    },
    wasChanged(v){
      let node_id = Math.abs(v);
      if(v<0) {
        console.log('de-selected %o', node_id);
        this.$emit('deselect', this.descendent_ids);
      } else {
        console.log('selected %o', node_id);
        this.$emit('select', this.descendent_ids);
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
</style>
