<template>
  <b-card class="mb-1" no-body="no-body">
    <b-card-header class="p-0" header-tag="header" role="tab">
      <div
        class="accordion-title"
        block="block"
        v-b-toggle.accordion-comp-brand
        :style="primaryColor"
      >
        {{ $t("search.label.brand_filtering") }}
        <b-img
          class="ml-2 accordion-arrow"
          src="@/assets/images/open.svg"
        ></b-img>
      </div>
    </b-card-header>

    <b-collapse
      id="accordion-comp-brand"
      visible="visible"
      accordion="accordion-comp-brand"
      role="tabpanel"
    >
      <b-card-body v-if="compatibilityBrands">
        <div class="top-search-group">
          <div class="input-search">
            <span><b-icon-search></b-icon-search></span>
            <b-form-input
              v-model="brandSearchTerm"
              :placeholder="$t('search.verbiage.begin_typing')"
              debounce="500"
            ></b-form-input>
          </div>
        </div>
        <b-form-checkbox-group
          class="search-brand-filter-wrap"
          :class="{ 'is-showing-all': showAll }"
          id="brand-check-group"
          @change="onChange"
          v-model="refineBrands"
        >
          <b-form-checkbox
            class="d-block"
            v-for="(brand, key) in brandsToShowInFilter"
            :key="'bid' + key"
            :value="brand.value"
            :class="{
              'is-popular': brand.is_popular,
              'is-divider': brand.divider,
            }"
          >
            <div v-if="brand.divider" class="divider"></div>
            <span v-else :class="{ 'font-weight-bold': brand.is_popular }">
              {{ brand.text }}
              <span class="font-weight-bold" :style="primaryColor">
                ({{ brand.count }})
              </span>
            </span>
          </b-form-checkbox>
        </b-form-checkbox-group>
        <div class="pagination" v-if="showPagination">
          <b-link href="#" @click="showAll = !showAll">
            {{
              showAll
                ? $t("search.label.show_less")
                : $t("search.label.show_all")
            }}
          </b-link>
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>
<script>
export default {
  props: {
    product_search_results: {
      type: Array,
      required: true,
    },
    primaryColor: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
      refineBrands: [],
      brandSearchTerm: "",
      compatibilityBrands: null,
    };
  },
  computed: {
    showPagination() {
      if (this.brandSearchTerm) {
        return false;
      }
      if (!this.compatibilityBrands.popularBrands.length) {
        return false;
      }
      return true;
    },
    brandsToShowInFilter() {
      if (!this.brandSearchTerm) {
        if (this.showAll) {
          return [
            ...this.compatibilityBrands.popularBrands,
            { divider: true },
            ...this.compatibilityBrands.nonPopularBrands,
          ];
        }
        return this.compatibilityBrands.popularBrands.length
          ? this.compatibilityBrands.popularBrands
          : this.compatibilityBrands.nonPopularBrands;
      }
      return [
        ...this.compatibilityBrands.popularBrands,
        ...this.compatibilityBrands.nonPopularBrands,
      ].filter((item) =>
        item.text.toLowerCase().includes(this.brandSearchTerm)
      );
    },
  },
  mounted() {
    this.setCompatibilityBrands();
  },
  watch: {
    product_search_results: {
      handler() {
        this.setCompatibilityBrands();
      },
    },
  },
  methods: {
    getBrandById(brandId) {
      return this.brands.find((item) => item.id === brandId);
    },
    setCompatibilityBrands() {
      let compBrands = {};
      this.product_search_results.forEach((product) => {
        product.oemCompatibilityBrands?.forEach((brand) => {
          if (!compBrands[brand.brand_id]) {
            const bObj = this.getBrandById(brand.brand_id);
            compBrands[brand.brand_id] = {
              text: brand.brand_en,
              value: brand.brand_id,
              is_popular: bObj.is_popular,
              count: 1,
            };
          } else {
            compBrands[brand.brand_id].count += 1;
          }
        });
      });
      const brandsList = Object.values(compBrands);
      const popularBrands = brandsList.filter((item) => item.is_popular);
      const nonPopularBrands = brandsList.filter((item) => !item.is_popular);
      this.compatibilityBrands = {
        popularBrands,
        nonPopularBrands,
      };
    },
    onChange() {
      this.$emit("input", this.refineBrands);
    },
  },
};
</script>
<style >
.is-divider {
  min-height: 4px;
  margin-bottom: 8px;
  border-bottom: 1px solid gray;
}
.is-divider .custom-control-label {
  display: none;
}
</style>