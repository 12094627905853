<template lang="pug">

b-modal#footer-inquiry-modal(title="Product Inquiry" size="lg" header-class="justify-content-center" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc)
    b-container.cart-modal-header(:style="!isSubmit? getStyle() : ''") 
      .header-title.text-center(v-if="!isSubmit") 
        .h4 {{ $t('inquiry_form.inquiry_form_heading') }}
      span.ml-auto.btn-modal-close
        a(href="#" @click="closeModal('footer-inquiry-modal')")
          b-img(src="@/assets/images/close_modal.svg" width="30") 
      p.header-description(v-if="!isSubmit") 
        | {{ $t('inquiry_form.inquiry_heading') }}
  
    b-container.inquiry-modal-body
      b-form(v-if="!isSubmit")
        b-row.input-equipment
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-name") 
                span.text-danger *
                | {{ $t('inquiry_form.first_name') }}:
              b-form-input#inquary-name(type="text" v-model="name" v-validate="{required: true}" :state="validateState('inquary-name')" name="inquary-name" :placeholder="$t('quote_modal.enter_name')" required)
              b-form-invalid-feedback(:state="validateState('inquary-name')") {{ $t('validation.name') }}

          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-email") 
                span.text-danger *
                | {{ $t('inquiry_form.email') }}:
              b-form-input#inquary-email(type="email" v-model="email" v-validate="{required: true, email: true}" name="inquary-email" :state="validateState('inquary-email')" :placeholder="$t('quote_modal.enter_email')" required)
              b-form-invalid-feedback(:state="validateState('inquary-email')") {{ $t('validation.email') }}

          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-phone") 
                span.text-danger *
                | {{ $t('inquiry_form.phone') }}:
              b-form-input#inquary-phone(type="number" v-model="phone" v-validate="{required: true}" :state="validateState('inquary-phone')" name="inquary-phone" :placeholder="$t('quote_modal.enter_phone_number')" required)
              b-form-invalid-feedback(:state="validateState('inquary-phone')") {{ $t('validation.phone_number') }} 

          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-company") 
                span.text-danger *
                | {{ $t('inquiry_form.company_name') }}:
              b-form-input#inquary-company(type="text" v-model="company" v-validate="{required: true}" name="inquary-company" :state="validateState('inquary-company')" :placeholder="$t('quote_modal.enter_comany_hospital_name')" required)
              b-form-invalid-feedback(:state="validateState('inquary-company')") {{ $t('validation.company_name') }}

          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-description") 
                span.text-danger *
                | {{ $t('inquiry_form.part_description') }}:
              b-form-textarea#inquary-description(v-model="description" v-validate="{required: true}" name="inquary-description" :state="validateState('inquary-description')" :placeholder="$t('inquiry_form.description')"  required)
              b-form-invalid-feedback(:state="validateState('inquary-description')") {{ $t('validation.description') }}
          
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-sku") 
                span {{ $t('inquiry_form.sku') }}:
              b-form-input#inquary-sku(type="text" v-model="sku" v-validate="{required: false}" name="inquary-sku" :state="validateState('inquary-sku')" :placeholder="$t('inquiry_form.sku')" required)
              //- b-form-invalid-feedback(:state="validateState('inquary-company')") Please enter company name
          
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-oem") 
                span {{ $t('inquiry_form.oem') }}:
              b-form-input#inquary-oem(type="text" v-model="oem" v-validate="{required: false}" name="inquary-oem" :state="validateState('inquary-oem')" :placeholder="$t('inquiry_form.oem_placeholder')" required)
              //- b-form-invalid-feedback(:state="validateState('inquary-company')") Please enter company name
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-oem") 
                span {{ $t('inquiry_form.model') }}:
              b-form-input#inquary-model(type="text" v-model="model" v-validate="{required: false}" name="inquary-model" :state="validateState('inquary-model')" :placeholder="$t('inquiry_form.model')" required)
              //- b-form-invalid-feedback(:state="validateState('inquary-company')") Please enter company name
          
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-qty") 
                span.text-danger *
                | {{ $t('inquiry_form.quantity_needed') }}:
              b-form-input#inquary-qty(type="text" v-model="quantity_needed" v-validate="{required: true}" name="inquary-qty" :state="validateState('inquary-qty')" :placeholder="$t('inquiry_form.enter_quantity')" required)
              b-form-invalid-feedback(:state="validateState('inquary-company')") {{ $t('validation.please_enter_quantity') }}

          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-supplier-cost") 
                span {{ $t('inquiry_form.current_supplier') }}:
              b-form-input#inquary-supplier-cost(type="number" v-model="supplier_cost" v-validate="{required: false}" :state="validateState('inquary-supplier-cost')" name="inquary-supplier-cost" :placeholder="$t('inquiry_form.enter_supplier_cost')" required)
              //- b-form-invalid-feedback(:state="validateState('inquary-supplier-cost')") Please enter supplier cost 
          
          b-col(cols="12")
            b-form-group#input-group-1
              label(for="inquary-phone") 
                span {{ $t('inquiry_form.annual_volume') }}:
              b-form-input#inquary-quantity(type="number" v-model="yearly_quantity" v-validate="{required: false}" :state="validateState('inquary-quantity')" name="inquary-quantity" :placeholder="$t('inquiry_form.enter_quantity')" required)
              //- b-form-invalid-feedback(:state="validateState('inquary-quantity')") Please enter quantity 

          b-col.mt-3.text-center(cols="12")
            b-button.text-light.pl-4.pr-4(variant="primary" size="lg" @click="submitInquary")
              span {{ $t('inquiry_form.submit') }}

      .submited-response(v-else)
        .msg-thank-you {{ $t('product.label.thankyou') }}
        .title-resp {{ $t('product.label.thankyou_submitting') }}
        .sub-title-resp {{ $t('product.label.we_will_reach') }}
        .home-link 
          b-button.text-light.pl-md-5.pr-md-5(variant="primary" @click="goToLanding()")
            span {{ $t('product.label.continue_browsing') }}

</template>

<script>
import axios from 'axios';
import { useDbStore } from '@/stores/db-st';
import { mapState, mapActions } from 'pinia';
import { useCartStore } from '@/stores/cart-st';
const img = require('@/assets/images/bg-brand-device-search.svg');
export default {
  name: 'ProductInquiryModal',
  components: {
  },
  data(){
    return {
      name: '',
      phone:'',
      email: '', 
      company: '',
      description: '',
      supplier_cost: '',
      yearly_quantity: '',
      quantity_needed: '',
      sku: '',
      oem: '',
      model: '',
      isSubmit: false,
    };
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(useDbStore, ['customer']),
  },
 
  created(){
    
  },

  mounted(){
    //- console.log('product**', this.product);
  },
  
  watch:{
  },

  methods:{
    closeModal(id){
      this.$bvModal.hide(id)
    },
    goToLanding(){
      this.isSubmit = false;
      if(this.$route.name != 'Landing') this.$router.push({name: 'Landing'});
      this.closeModal('footer-inquiry-modal');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getStyle(){
      return `background-image: url("${img}");`;
    },
    async submitInquary(){
      this.cart_busy = true;
      try{
        let is_valid = await this.$validator.validateAll();
        if (!is_valid) return; 
        let contact = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          company: this.company,
          description: this.description,
          supplier_cost: this.supplier_cost,
          yearly_quantity: this.yearly_quantity,
          quantity_needed: this.quantity_needed,
          sku: this.sku,
          oem: this.oem,
          model: this.model,
          opcCustomerEmail: this.customer.email,
          opcCustomerId: this.customer.id,
          marketingRegionId: this.customer.marketing_region_id
        };
        let result = await axios.post(process.env.VUE_APP_API_BASE_URL  + '/email/inquiryForm', {
          inquary_quote: contact
        });
        //- console.log('result**', result);
        this.name = "";
        this.email = "";
        this.phone = "";
        this.company = "";
        this.description = "";
        this.supplier_cost = "";
        this.yearly_quantity = "";
        this.quantity_needed = "";
        this.yearly_quantity = "";
        this.sku = "";
        this.oem = "";
        this.model = "";
        this.isSubmit = true;
      } catch(err){
        console.error(err);
      } finally {
        this.cart_busy = false;
      }
    }
  }
  
}
</script>
<style>
  #footer-inquiry-modal .modal-body{
    padding: 0rem;
  }
</style>
<style scoped lang="scss">
#footer-inquiry-modal{
  .cart-modal-header {
    padding: 1rem;
    .header-title{
      margin-top: 5px;
    }
    .btn-modal-close{
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .header-description{
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 1rem;
      font-size:14px;
      @media (max-width: 767px){
        padding: 1rem;
        margin: 0;
      }
    }
  }
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .inquiry-modal-body{
    padding: 1rem 4rem 2rem;
    @media (max-width: 767px){
      padding: 1rem 2rem 2rem;
    }
    .input-equipment{
      label{
        font-size: 14px;
        font-weight: 500;
      }
      input{
        font-size: 14px;
      }
      textarea{
        font-size: 14px;
      }
    }
    .submited-response{
      text-align: center;
      margin: 3rem;
      @media (max-width: 767px){
        margin: 3rem 1rem;
      }
      .msg-thank-you{
        font-size: 36px;
        font-weight: 500;
        color: #68C3C5;
        padding: .5rem;
      }
      .title-resp{
        padding-top: .5rem;
        font-size: 14px;
      }
      .sub-title-resp{
        padding: .5rem;
        font-size: 14px;
      }
      .home-link{
        padding: .5rem;
      }
    }
  }
}
</style>
