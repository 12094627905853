<template lang="pug">
#hrader.bg-white.pt-2.pt-md-3.pl-md-3.pr-md-3(:class="{ 'fixed-header-ontop': currentPage == 'Search' }")
  b-overlay(:show="busy" variant="light" opacity="0.5" no-wrap fixed)
    template( #overlay )
      b-spinner( size="md" variant="secondary")
  //- @dismissed="dismissCountDown=0" 
  b-alert.text-center(:show="has_message || has_error" dismissible variant="danger" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;")
    .global-error(v-show="has_message || has_error")
      h6(v-if="message") {{message}}
      p(v-if="error") {{error}}
  b-container.hdr.d-flex.justify-content-between(fluid).align-items-center
    .text-left.logo-img-block.d-flex.align-items-center
      b-img.mr-3.d-block.d-md-none(fluid src="@/assets/images/menu-mobile.svg" v-b-toggle.sidebar-backdrop)
      b-sidebar#sidebar-backdrop(backdrop shadow :backdrop-variant="variant")
        .px-3.py-2.mob-side-nav-hader_wrap
          p.mob-nav-title-hading() {{ $t('global.menu.accessories') }}
          nav.mb-3
            b-nav(vertical)
              b-nav-item.mob-nav-item(active v-for="c in accessory_categories" :key="c.id" @click="setCategory(c.id); toggleSidebar();" :class="{'disabled': c.nodes && c.nodes.length}" ) 
                b-icon.sub-nav-arrow-mob(v-if="c.nodes && c.nodes.length" icon="caret-down-fill" aria-hidden="true") 
                TranslateValue(:sourceObj="c")
                .sub-menu-item.pl-1(v-if="c.nodes && c.nodes.length")
                  b-nav-item.mob-nav-item(v-for="category in c.nodes" :key="category.id" @click.stop="setCategory(category.id); toggleSidebar();" ) 
                    //- b-icon.sub-nav-arrow-mob(v-if="category.nodes && category.nodes.length" icon="caret-down-fill" aria-hidden="true") 
                    TranslateValue(:sourceObj="category")
                    //- .sub-menu-item.pl-2(v-if="category.nodes && category.nodes.length")
                    //-   b-nav-item.mob-nav-item(v-for="subCategory in category.nodes" :key="subCategory.id" @click.stop="setCategory(subCategory.id); toggleSidebar();" ) {{ subCategory.name_en }}
                        
          p.mob-nav-title-hading(v-if="customer && customer.display_parts") {{ $t('global.menu.parts') }} 
          nav.mb-3(v-if="customer && customer.display_parts")
            b-nav(vertical)
              b-nav-item.mob-nav-item(v-for="c in part_categories" :key="c.id" @click="setCategory(c.id); toggleSidebar();" ) 
                b-icon.sub-nav-arrow-mob(v-if="c.nodes && c.nodes.length" icon="caret-down-fill" aria-hidden="true") 
                TranslateValue(:sourceObj="c")
                .sub-menu-item.pl-1(v-if="c.nodes && c.nodes.length")
                  b-nav-item.mob-nav-item(v-for="category in c.nodes" :key="category.id" @click.stop="setCategory(category.id); toggleSidebar();" ) 
                    //- b-icon.sub-nav-arrow-mob(v-if="category.nodes && category.nodes.length" icon="caret-down-fill" aria-hidden="true") 
                    TranslateValue(:sourceObj="category")
                    //- .sub-menu-item.pl-2(v-if="category.nodes && category.nodes.length")
                    //-   b-nav-item.mob-nav-item(v-for="subCategory in category.nodes" :key="subCategory.id" @click.stop="setCategory(subCategory.id); toggleSidebar();" ) {{ subCategory.name_en }}
                
          nav.mb-3
            b-nav(vertical)
              b-nav-item.new-arrivals-nav( href="#" @click="toggleSidebar(); executeNewArivalProductSearch()") {{ $t('landing.label.new_arrivals') }}

          nav.mb-3.border-top.border-bottom.border-secondary.pt-3
            b-nav(vertical v-if="customer")
              b-nav-item.mob-nav-item-info(:href="'tel://'+translateText(customer,'phone', true)" target="_blank") 
                b-icon-telephone-fill
                span.ml-1 {{translateText(customer,'phone', true)}}
              
              b-nav-item.mob-nav-item-info( :href="'mailto:'+translateText(customer, 'email', true)" target="_blank") 
                b-icon-envelope-fill
                
                span.ml-1 {{translateText(customer,'email', true)}}
       
              b-nav-item.mob-nav-item-info(href="#" @click="openWebsitefeedbackModal()" v-if="isMedtenCustomer") {{ $t('global.menu.website_feedback') }}
              
              b-nav-item.mob-nav-item-info(v-if="!isMedtenCustomer" :href="customer.about_url" target="_blank") {{ $t('global.menu.about_us') }}

          .about-group( v-if="!isMedtenCustomer")
            p.mob-nav-title-hading() {{$t('global.label.medten_inc')}}
            nav.mb-3
              b-nav(vertical)
                b-nav-item.mob-nav-item(:to="{name: 'About'}") Welcome
                b-nav-item.mob-nav-item(:to="{name: 'Sourcing'}") {{$t('landing.label.sourcing')}}

            p.mob-nav-title-hading() {{$t('landing.label.products')}}
            nav.mb-3
              b-nav(vertical)
                b-nav-item.mob-nav-item(:to="{name: 'General'}") {{$t('global.label.general')}}
                b-nav-item.mob-nav-item(:to="{name: 'Accessories'}") Accessories
                b-nav-item.mob-nav-item(:to="{name: 'Parts'}") Parts
                b-nav-item.mob-nav-item(:to="{name: 'Equipment'}") {{$t('equipments.equipment')}}
            
            p.mob-nav-title-hading() {{$t('search.label.resources')}}
            nav.mb-3
              b-nav(vertical)
                b-nav-item.mob-nav-item(:to="{name: 'Downloads'}") {{$t('global.menu.downloads')}}
                b-nav-item.mob-nav-item(:to="{name: 'FAQ'}") {{$t('search.verbiage.faq')}}

      b-link(href="#" v-if="isChinese && isMedtenCustomer" @click="resetSearch()")
        b-img.logo-img(fluid src="../assets/images/Welcome/cn-logo.png")
      b-link(href="#" v-else @click="resetSearch()")
        b-img.logo-img(fluid :src="logo_image_url")
    b-container(fluid).d-none.d-md-block
      b-input-group.w-100
        b-form-input.shadow-manual.bg-white.border-0( type="text" size="md" v-model="search_term" :disabled="busy" @keydown.enter="executeProductSearch('term')" :placeholder="$t('global.verbiage.search')")
        b-input-group-append.shadow-manual
          b-button(size="md" @click="executeProductSearch('term')" variant="primary" :style="primaryBgColor").text-light
            b-icon-search
       
      //- small {{ $t('global.verbiage.search') }}
      
    b-nav#auth-panel(vertical v-if="isMedtenCustomer && !isChinese").text-right
      b-nav-item-dropdown( v-if="user_principal" :text="$t('auth.hello') + ' ' + user_principal.first_name + '!'")
        b-dropdown-item( @click="executeLogout" ) Logout
      b-nav-item( v-else )
        b-link(:to="{name:'Login'}")
          span {{ $t('auth.login') }}
    .text-right.cart-btn-block
      //- .text-primary(style="font-size: .9em; line-height: 1;")  {{ $t('global.verbiage.build_order') }}
      b-button.text-light.shadow-manual(variant="primary" @click="toggleCart" :style="primaryBgColor").p-2.full-width
        b-icon-cart-fill 
        span.ml-2 {{cartCount > 0 ? ($t('global.label.my_quote') + ' (' + cartCount + ')') : $t('global.label.my_quote_list')}}
  b-container(fluid).d-block.d-md-none.mb-3
    b-input-group.mt-2( style="width: 100%;" )
      b-form-input.shadow-manual.bg-white.border-0.mob-header-search-input(size="md" type="text" v-model="search_term" :disabled="busy" @keydown.enter="executeProductSearch('term')" :placeholder="$t('global.verbiage.search_mob')")
      b-input-group-append.shadow-manual
        b-button(size="md" @click="executeProductSearch('term')" variant="primary" :style="primaryBgColor").text-light
          b-icon-search

  b-navbar(toggleable="md").d-none.d-md-block.pt-0.desktop-nav-block
    //- b-navbar-brand(href="#") NavBar
    b-navbar-toggle(target="nav-collapse")
    b-collapse#nav-collapse(:is-nav="true")
      b-navbar-nav
        b-nav-item( href="#" @click="resetSearch()" ) {{ $t('product.label.home') }}
        
        b-nav-item-dropdown( href="#" :text="$t('global.menu.accessories')" ) 
          b-dropdown-item.dropdown-list-item(v-for="c in accessory_categories" :key="c.id" @click="setCategory(c.id)")  
            TranslateValue(:sourceObj="c")
            b-icon.sub-nav-arrow(v-if="c.nodes && c.nodes.length" icon="chevron-right" aria-hidden="true")
            .sub-nav-item-dropdown(v-if="c.nodes && c.nodes.length")
              b-dropdown-item(v-for="category in c.nodes" :key="category.id" @click.stop="setCategory(category.id)" ) 
                TranslateValue(:sourceObj="category")
                b-icon.sub-nav-arrow(v-if="category.nodes && category.nodes.length" icon="chevron-right" aria-hidden="true")
                .nested-nav-item-dropdown(v-if="category.nodes && category.nodes.length")
                  b-dropdown-item(v-for="subCategory in category.nodes" :key="subCategory.id" @click.stop="setCategory(subCategory.id)" ) 
                    TranslateValue(:sourceObj="subCategory")
        b-nav-item-dropdown( href="#" :text="$t('global.menu.parts')" v-if="customer && customer.display_parts")  
          b-dropdown-item.dropdown-list-item(v-for="c in part_categories" :key="c.id" @click="setCategory(c.id)" ) 
            TranslateValue(:sourceObj="c")
            b-icon.sub-nav-arrow(v-if="c.nodes && c.nodes.length" icon="chevron-right" aria-hidden="true")
            .sub-nav-item-dropdown(v-if="c.nodes && c.nodes.length")
              b-dropdown-item(v-for="category in c.nodes" :key="category.id" @click.stop="setCategory(category.id)" ) 
                TranslateValue(:sourceObj="category")
                //- b-icon.sub-nav-arrow(v-if="category.nodes && category.nodes.length" icon="chevron-right" aria-hidden="true")
                //- .nested-nav-item-dropdown(v-if="category.nodes && category.nodes.length")
                //-   b-dropdown-item(v-for="subCategory in category.nodes" :key="subCategory.id" @click.stop="setCategory(subCategory.id)" ) {{ subCategory.name_en }}
        
        b-nav-item(href="#" @click="executeNewArivalProductSearch") {{ $t('landing.label.new_arrivals') }}
      
      

      b-navbar-nav.ml-auto( v-if="customer" )
        b-nav-item( :href="'tel://'+translateText(customer,'phone', true)" target="_blank") 
          b-icon-telephone-fill
          span.ml-1 {{translateText(customer,'phone', true)}}
       
        b-nav-item( :href="'mailto:'+translateText(customer,'email', true)" target="_blank") 
          b-icon-envelope-fill
          span.ml-1 {{translateText(customer,'email', true)}}

        //- b-nav-item( href="#" @click="openWebsitefeedbackModal()" v-if="isMedtenCustomer") {{ $t('global.menu.website_feedback') }}

        b-nav-item( v-if="!isMedtenCustomer" :href="customer.about_url" target="_blank") {{ $t('global.menu.about_us') }}
        b-nav-item-dropdown.about-us-dropdown(v-else :text="$t('global.menu.about_us')" right)
          b-nav-item
            b-row.about-us-menus 
              b-col.about-us-menu-group(cols="4")
                .menu-header {{$t('global.label.medten_inc')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'About'}")
                    b-icon-dot
                    | {{ $t('global.label.welcome') }}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'Sourcing'}") 
                    b-icon-dot
                    | {{$t('landing.label.sourcing')}}
              b-col.about-us-menu-group(cols="4")
                .menu-header {{$t('landing.label.products')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'General'}")
                    b-icon-dot
                    | {{$t('global.label.general')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'Accessories'}") 
                    b-icon-dot
                    | {{$t('global.menu.accessories')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'Parts'}")
                    b-icon-dot
                    | {{$t('global.menu.parts')}}
                .menu-item  
                  //- b-dropdown-item(href="#" :to="{name: 'Equipment'}") 
                  //-   b-icon-dot
                  //-   | {{$t('equipments.equipment')}}

              b-col.about-us-menu-group(cols="4")
                .menu-header {{$t('search.label.resources')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'Downloads'}") 
                    b-icon-dot
                    | {{$t('global.menu.downloads')}}
                .menu-item  
                  b-dropdown-item(href="#" :to="{name: 'FAQ'}") 
                    b-icon-dot
                    | {{$t('search.verbiage.faq')}}
  
  UserCart 
  WebsiteFeedback
</template>

<script>
import { VueOfflineStorage } from 'vue-offline';
import UserCart from '@/components/UserCart.vue';
import WebsiteFeedback from '@/components/WebsiteFeedback.vue';
import TranslateValue from '@/components/TranslateValue.vue';
import { mapState, mapActions, mapGetters } from 'pinia';
import { useAuthStore } from '@/stores/auth-st';
import { useBaseStore } from '@/stores/base-st';
import { useDbStore } from '@/stores/db-st';
import { useCartStore } from '@/stores/cart-st';
import { useProductSearchStore } from '@/stores/product-search-st';
import utils from '@/mixins/utils';
const ACCESSORY_CATEGORY_ID = 1;
export default {
  name: 'Header',
  mixins: [utils],
  components: {
    UserCart,
    TranslateValue,
    WebsiteFeedback,
  },
  data() {
    return {
      showSidebar: false,
      search_term: '',
      cart_fields: [
        { key: 'sku', label: 'SKU' },
        { key: 'quantity', label: 'Qty.', class: 'text-right' },
        { key: 'name', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'opts', label: '' },
      ],
      category_id: null, // from menu selects
      variant: 'dark',
    };
  },

  computed: {
    ...mapState(useAuthStore, ['user_principal']),
    ...mapState(useBaseStore, [
      'message',
      'error',
      'errors',
      'busy',
      'global_error',
      'has_error',
      'has_global_error',
      'has_message',
    ]),
    ...mapState(useProductSearchStore, [
      'product_search_results',
      'product_search_term',
      'product_search_total',
      'search_criteria_provided',
    ]),
    ...mapState(useCartStore, ['cart_contact', 'cart_items']),
    ...mapState(useDbStore, [
      'customer',
      'categories',
      'isMedtenCustomer',
      'primaryColor',
      'secondaryColor',
      'primaryBgColor',
      'secondaryBgColor',
      'primaryBgColorWithoutBorder',
      'secondaryBgColorWithoutBorder',
      'secondaryBorder',
    ]),
    products() {
      return this.product_search_results.map((p) => {
        return { text: `${p.type} : ${p.label}`, id: p.id };
      });
    },
    //- accessory_categories(){ return this.categories.filter(c=>c.parent_id===1); },
    //- part_categories(){ return this.categories.filter(c=>c.parent_id===2); },
    cartCount() {
      let count = 0;
      this.cart_items.map((item) => {
        count = Number(count) + Number(item.quantity);
      });
      return count;
    },

    currentPage() {
      return this.$route.name;
    },
    part_categories() {
      let parts = this.categories.filter((c) => c.parent_id === 2);
      parts.forEach((part) => {
        part['nodes'] = [];
        let subCategories = this.categories.filter(
          (c) => c.parent_id === part.id
        );
        if (subCategories && subCategories.length) {
          part['nodes'] = this.sortRanks(subCategories);
          part['nodes'].forEach((nestedPart) => {
            let nestedSubCategory = this.categories.filter(
              (c) => c.parent_id === nestedPart.id
            );
            if (nestedSubCategory && nestedSubCategory.length) {
              nestedPart['nodes'] = this.sortRanks(nestedSubCategory);
            }
          });
        }
      });
      //- console.log('parts', parts);
      return this.sortRanks(parts);
    },
    accessory_categories() {
      let accessories = this.categories.filter(
        (c) => c.parent_id === ACCESSORY_CATEGORY_ID
      );
      accessories.forEach((accessory) => {
        accessory['nodes'] = [];
        let subCategories = this.categories.filter(
          (c) => c.parent_id === accessory.id
        );
        if (subCategories && subCategories.length) {
          accessory['nodes'] = this.sortRanks(subCategories);
          accessory['nodes'].forEach((nestedAccessory) => {
            let nestedSubCategory = this.categories.filter(
              (c) => c.parent_id === nestedAccessory.id
            );
            if (nestedSubCategory && nestedSubCategory.length) {
              nestedAccessory['nodes'] = this.sortRanks(nestedSubCategory);
            }
          });
        }
      });
      return this.sortRanks(accessories);
    },
    logo_image_url() {
      if (!this.customer) return '';
      return this.customer.logo_url;
    },
  },

  created() {
    this.hydrate();
    useAuthStore().hydrate();
    if (!this.$router.currentRoute || !this.$router.currentRoute.name)
      this.$router.push({ name: 'Landing' });
  },

  mounted() {
    if (this.$route.name === 'Landing') {
      this.removeAllCriterion();
    }
    this.search_term = this.product_search_term;
  },

  watch: {
    product_search_term(now, then) {
      this.search_term = now;
    },
  },

  methods: {
    ...mapActions(useAuthStore, ['logout']),
    ...mapActions(useBaseStore, ['flashMessage']),
    ...mapActions(useDbStore, [
      'loadBrands',
      'loadCategories',
      'loadFilterOptions',
      'loadModels',
    ]),
    ...mapActions(useProductSearchStore, [
      'searchProducts',
      'removeAllCriterion',
      'newArrivalProductSearch',
      'resetSearchOptions',
    ]),
    openWebsitefeedbackModal() {
      this.$bvModal.show('website-feedback-modal');
    },
    sortRanks(data) {
      const recordsWithNoRank = data.filter((item) => !item.rank_us);
      const recordsWithRank = data.filter((item) => item.rank_us);
      return [
        ...recordsWithRank.sort((a, b) => a.rank_us - b.rank_us),
        ...recordsWithNoRank.sort((a, b) => a.name_en.localeCompare(b.name_en)),
      ];
    },
    resetSearch() {
      this.resetSearchOptions();
      if (this.$route.name != 'Landing') {
        this.$router.push({ name: 'Landing' });
      }
    },
    getSubCategories(category) {
      //- console.log('category', category.id, category.parent_id, this.categories.filter(c=>c.parent_id===4));
      return this.categories.filter((c) => c.parent_id === category.id);
    },
    async hydrate() {
      await Promise.allSettled([
        this.loadBrands(),
        this.loadCategories(),
        //- this.loadFilterOptions({visible_in_catalog: 1, order_by:['category_en','option_en']}),
        this.loadFilterOptions({ order_by: ['category_en', 'option_en'] }),
        this.loadModels(),
      ]);
      this.search_term = this.product_search_term || '';
    },
    buildTree(category) {
      let node = {
        text: category.name_en,
        id: category.id,
        nodes: [],
        visible: true,
      };
      let children = this.categories.filter((c) => {
        return c.parent_id == category.id;
      });
      children.forEach((child) => {
        let childNode = this.buildTree(child);
        node.nodes.push(childNode);
      });
      return node;
    },
    toggleSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop');
    },
    async executeProductSearch(mode) {
      // Flag to track if the timeout message has been displayed
      let timeoutMessageDisplayed = false;

      // Start a timer to track the search duration
      const searchTimeout = setTimeout(() => {
        this.flashMessage(
          'Please narrow your search by selecting a subcategory',
          3
        );
        timeoutMessageDisplayed = true;
      }, 10000); // 10 seconds

      try {
        switch (mode) {
          case 'category':
            this.search_term = '';
            break;
          case 'term':
            this.category_id = null;
          case 'model':
          case 'brand':
          default:
            this.category_id = null;
        }
        this.clearSearch();
        let parms = {};
        parms.product_search_term = this.search_term;
        parms.brand_ids = [];
        parms.model = '';
        if (this.category_id) {
          // Get all immediate-child categories as well
          let child_category_ids = this.categories
            .filter((c) => c.parent_id == this.category_id)
            .map((c) => c.id);
          let sub_child_category_ids = [];
          child_category_ids.forEach((cid) => {
            let c_ids = this.categories
              .filter((c) => c.parent_id == cid)
              .map((c) => c.id);
            sub_child_category_ids = [...sub_child_category_ids, ...c_ids];
          });
          parms.category_ids = [
            this.category_id,
            ...child_category_ids,
            ...sub_child_category_ids,
          ];
        } else {
          parms.category_ids = [];
        }

        await this.searchProducts(parms);

        // Clear the timeout if search completes within 30 seconds
        clearTimeout(searchTimeout);

        // If the timeout message has been displayed, do not proceed with other messages
        if (timeoutMessageDisplayed) {
          return;
        }

        // Trigger product selection when only one result
        if (
          this.product_search_total === 1 &&
          this.product_search_results[0].id
        ) {
          if (this.$route.name !== 'Product') {
            this.$router.push({
              name: 'Product',
              params: { product_id: this.product_search_results[0].id },
            });
          } else if (
            this.$route.params &&
            this.$route.params['product_id'] &&
            this.$route.params['product_id'] !=
              this.product_search_results[0].id
          ) {
            this.$router.push({
              name: 'Product',
              params: { product_id: this.product_search_results[0].id },
            });
          }
        } else if (this.product_search_total > 1) {
          if (this.$route.name !== 'Search') {
            this.$router.push({
              name: 'Search',
              params: { search_term: this.search_term },
            });
          }
        } else if (this.product_search_total === 0) {
          let msg = '';
          if (this.search_term) {
            msg =
              'There were no results for search: "' + this.search_term + '"';
          }
          if (this.category_id) {
            let category = this.categories.find(
              (c) => c.id == this.category_id
            );
            msg = 'Products in category "' + category.name_en + '" coming soon';
          }
          this.flashMessage(msg, 3);
        }
      } catch (error) {
        // Handle error if search fails
        clearTimeout(searchTimeout);
        if (!timeoutMessageDisplayed) {
          console.error('Error during product search:', error);
          this.flashMessage(
            'An error occurred during the product search. Please try again later.',
            3
          );
        }
      }
    },

    async executeNewArivalProductSearch() {
      this.clearSearch();
      //- useProductSearchStore().$reset(); // when executed from here, always starts a totally fresh search.
      await this.newArrivalProductSearch(this.$t('landing.label.new_arrivals'));
      //Trigger product selection when only one result.
      if (
        this.product_search_total === 1 &&
        this.product_search_results[0].id
      ) {
        if (this.$route.name !== 'Product')
          this.$router.push({ name: 'Product', params: { product_id: p.id } });
      } else if (this.product_search_total > 1) {
        if (this.$route.name !== 'Search') {
          this.$router.push({
            name: 'Search',
            params: { search_term: this.search_term },
          });
        }
      }
      if (this.product_search_total === 0) {
        useBaseStore().$patch({
          message: this.$t(`global.verbiage.product_not_found`),
        });
      }
    },

    async executeLogout() {
      await this.logout();
      if (this.$router.currentRoute.name !== 'Landing') {
        this.$router.push({ name: 'Landing' });
      }
    },
    clearSearch() {
      VueOfflineStorage.set('refine_filter_options', null);
      VueOfflineStorage.set('refine_models', null);
      VueOfflineStorage.set('refine_brands', null);
      VueOfflineStorage.set('refine_categories', null);
      VueOfflineStorage.set('refine_lifecycles', null);
    },
    setCategory(cid) {
      // debugger
      this.clearSearch();
      this.category_id = cid;
      this.executeProductSearch('category');
    },

    toggleCart() {
      this.$bvModal.show('cart');
    },
  },
};
</script>
<style lang="scss">
.dropdown-list-item {
  position: relative;
  .dropdown-item {
    display: flex;
    align-items: center;
    .sub-nav-arrow {
      margin-left: auto;
    }
    .sub-nav-item-dropdown {
      display: none;
      position: absolute;
      background: white;
      border: 1px solid #d6d6d6;
      width: 100%;
      border-radius: 0px 5px 5px 0px;
      left: 100%;
      top: 0;
      .dropdown-item {
        position: relative;
        .nested-nav-item-dropdown {
          display: none;
          position: absolute;
          background: white;
          border: 1px solid #d6d6d6;
          width: 100%;
          border-radius: 0px 5px 5px 0px;
          left: 100%;
          top: 0;
        }
        &:hover {
          .nested-nav-item-dropdown {
            display: block;
          }
        }
      }
    }
    &:hover {
      .sub-nav-item-dropdown {
        display: block;
      }
    }
  }
}
.about-us-dropdown {
  .dropdown-menu {
    width: 500px;
    padding: 1rem;
    .about-us-menus {
      .about-us-menu-group {
        .menu-header {
          margin-left: 8px;
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 5px;
          color: black;
        }
        .menu-item {
          font-size: 1rem;
          font-weight: 400;
          cursor: pointer;
          color: #7a7a7a;
          li {
            a {
              padding-left: 0;
              text-decoration: none;
              color: #7a7a7a;
              &:hover {
                color: #212121;
                background: white;
              }
            }
            &:hover {
              background: white;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
#hrader {
  box-shadow: 0 3px 5px #efefef;
  height: 133px;
  @media (max-width: 767px) {
    box-shadow: none !important;
  }
}
#auth-panel {
  width: 10vw;
}
.sub-nav-arrow-mob {
  padding-right: 4px;
  padding-top: 2px;
}
.fixed-header-ontop {
  position: fixed;
  z-index: 9;
  margin: 0 !important;
  width: 100%;
  padding: 0 1rem;
}
.logo-img-block {
  width: 25vw;
}
.logo-img-block .logo-img {
  max-width: 177px;
}
.cart-btn-block {
  width: 20vw;
}
.mob-side-nav-hader_wrap .mob-nav-title-hading {
  padding-left: 1rem;
  margin-bottom: 5px;
  color: gray;
}
.mob-side-nav-hader_wrap .mob-nav-item {
  &.disabled {
    pointer-events: none;
    .sub-menu-item {
      pointer-events: all;
    }
  }
}
.mob-side-nav-hader_wrap .mob-nav-item a {
  padding: 0.2rem 1rem;
  font-size: 13px;
  color: black;
}
.mob-side-nav-hader_wrap .new-arrivals-nav a {
  padding: 0.2rem 1rem;
  color: gray;
}
.mob-side-nav-hader_wrap .new-arrivals-nav a:hover {
  color: black;
}
.mob-header-search-input {
  padding: 20px 10px;
  font-size: 14px;
}
.mob-side-nav-hader_wrap .mob-nav-item-info a {
  padding: 0.5rem 1rem;
  font-size: 13px;
  color: gray;
}
.shadow-manual {
  box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
}
@media (max-width: 375px) {
  .cart-btn-block .btn {
    font-size: 14px;
  }
  .cart-btn-block .btn .b-icon.bi {
    width: 15px;
  }
}

@media (max-width: 796px) {
  #auth-panel {
    width: 25vw;
  }
  .cart-btn-block {
    width: 40vw;
  }
  .logo-img-block .logo-img {
    max-height: 70px;
  }
  .desktop-nav-block {
    padding-top: 10px !important;
  }
}

@media (min-width: 797px) and (max-width: 960px) {
  #auth-panel {
    width: 25vw;
  }
  .cart-btn-block {
    width: 32vw;
  }
  .logo-img-block .logo-img {
    max-width: 140px;
  }
}
@media (min-width: 961px) and (max-width: 1140px) {
  #auth-panel {
    width: 20vw;
  }
  .cart-btn-block {
    width: 25vw;
  }
}
@media (min-width: 980px) {
  #auth-panel {
    width: 20vw;
  }
  .cart-btn-block {
    width: 25vw;
  }
  .logo-img-block {
    min-width: 190px;
  }
  .logo-img-block .logo-img {
    position: absolute;
    top: 10px;
  }
  .desktop-nav-block {
    margin-top: 25px;
    margin-left: 200px;
  }
}
@media (min-width: 980px) and (max-width: 1240px) {
  .desktop-nav-block {
    font-size: 12px !important;
  }
}
</style>
