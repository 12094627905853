<template lang="pug">
#footer(:class="{ 'search-filter-padding': isSearch }")
  b-container.p-md-5.text-center.footer-info-block(
    fluid,
    :style="getFooterStyle('bg')",
    v-if="customer"
  )
    p.info-main {{ $t("search.verbiage.cannot_find") }}
    div(v-if="!isMedtenCustomer") 
      |
      p.info-sub {{ $t("search.verbiage.emailus_at") }}&nbsp;
        a.email-to(:href="'mailto:' + translateText(customer, 'email', true)") {{ translateText(customer, "email", true) }}
    div(v-else) 
      |
      p.info-sub {{ $t("search.verbiage.inquiryform_fillout") }}&nbsp;
        |
        a.inquiry-to(href="#", @click="openModal('footer-inquiry-modal')") {{ $t("search.verbiage.inquiryform_fillout_postfix") }}&nbsp;
        span(v-if="!isChinese") or
        |
        | {{ $t("search.verbiage.email_us_at") }}&nbsp;
        a.email-to(:href="'mailto:' + translateText(customer, 'email', true)") {{ translateText(customer, "email", true) }}
  b-container.p-2(fluid)
    .copyright.text-center {{ $t("search.verbiage.all_rights") }}
      |
      | {{ $t("global.label.please_review") }}
      |
      b-link(href="#", :to="{ name: 'PrivacyPolicy' }") {{ $t("search.verbiage.privacy_policy") }}
      |,&nbsp
      b-link(href="#", :to="{ name: 'TermsOfUse' }") {{ $t("search.verbiage.terms_of_use") }}
      | , {{ $t("global.label.and") }}
      |
      b-link(href="#", :to="{ name: 'TrademarkNotice' }") {{ $t("search.verbiage.trademark_notice") }}
      | .
      a(href="https://beian.miit.gov.cn/", target="_blank") 粤ICP备19022481号
  .version.text-center Version {{ $packageJSON.version }}
  FooterInquiryModal
</template>

<script>
import { mapState, mapGetters } from "pinia";
import { useBaseStore } from "@/stores/base-st";
import { useDbStore } from "@/stores/db-st";
import FooterInquiryModal from "@/components/FooterInquiryModal.vue";
import utils from "@/mixins/utils";
const imgLandingBg = require("@/assets/images/landing/landing-footer-bg.svg");
export default {
  name: "Footer",
  components: {
    FooterInquiryModal,
  },
  data() {
    return {};
  },
  mixins: [utils],
  props: {
    isSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useBaseStore, ["message"]),
    ...mapState(useDbStore, ["customer"]),
    ...mapGetters(useDbStore, [
      "isMedtenCustomer",
      "primaryColor",
      "secondaryColor",
      "primaryBgColor",
      "secondaryBgColor",
    ]),
  },

  created() {},

  mounted() {},

  watch: {},

  methods: {
    getFooterStyle(type) {
      switch (type) {
        case "bg":
          if (this.isMedtenCustomer) {
            return `background-image: url("${imgLandingBg}");`;
          }
          return this.primaryBgColor;
          break;
        default:
          if (!this.isMedtenCustomer) {
            return { color: "#ffff" };
          }
          return {};
      }
    },

    openModal(id) {
      this.$bvModal.show(id);
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  &.search-filter-padding {
    padding-left: 25vw;
  }
  .footer-info-block {
    .info-main {
      font-size: 3rem;
      font-weight: 600;
      color: white;
    }
    .info-sub {
      font-size: 1.5rem;
      font-weight: 400;
      color: #d0fdff;
      a {
        color: white;
        text-decoration: underline;
        &:hover {
          color: white;
          text-decoration: underline;
        }
        &.inquiry-to {
          text-decoration: underline;
        }
        &.email-to {
          text-decoration: none;
        }
      }

      // color: white;
      // a{
      //   color: white;
      //   text-decoration: none;
      //   &:hover{
      //     color: white;
      //     text-decoration: none;
      //   }
      // }
    }
  }
  .version {
    color: darkgray;
  }
  @media (max-width: 567px) {
    .footer-info-block {
      .info-sub {
        font-size: 1.2rem;
        font-weight: 500;
        color: #d0fdff;
        a {
          color: white;
          text-decoration: underline;
          &:hover {
            color: white;
            text-decoration: underline;
          }
          &.email-to {
            text-decoration: none;
          }
          &.inquiry-to {
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .footer-info-block {
      padding-top: 1rem;
      padding-bottom: 1rem;
      .info-main {
        font-size: 2rem;
        font-weight: 600;
        color: white;
      }
    }
  }
  .copyright {
    font-size: 0.85rem;
    a {
      color: black;
      &:hover {
        color: #38bcd9;
      }
    }
  }
}
@media (max-width: 1000px) {
  #footer {
    &.search-filter-padding {
      padding-left: 0vw;
    }
  }
}
</style>